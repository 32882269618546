import React, { useState, useEffect, CSSProperties } from "react"
import { motion, useAnimation } from "framer-motion"



interface LogoProps {
    width?: number,
    height?: number,
    style?: CSSProperties,
    color?: string,
}

const Logo = ({ width, height, style, color = "var(--logo-color)" }: LogoProps) => {
    const playfullGroup = {
        hidden: {

        },
        visible: {
            transition: {
                when: "afterChildren",
                staggerChildren: 0.2
            }
        },
        exit: {
            transition: {
                when: "afterChildren",
                staggerChildren: 0.3
            }
        }
    }

    const circle = {
        hidden: {
            strokeWidth: 4,
            r: 1,
            cx: 3,
            cy: 10.5,
            stroke: color,
            opacity: 0,
            fill: "none"
        },
        visible: {
            strokeWidth: 4,
            r: 9.5,
            cx: 73.5,
            cy: 10.5,
            stroke: color,
            opacity: 1,
            fill: "none",
            transition: {
                type: "spring",
                damping: 12,
                stiffness: 50,
            }
        },
        exit: {
            r: 1,
            cx: 3,
            cy: 10.5,
            stroke: color,
            opacity: 0,
            transition: {
                type: "spring",
                damping: 12,
                stiffness: 50,
            }
        }
    }

    const vertRect = {
        hidden: {
            x: 31,
            y: 100,
            width: 20,
            height: 10,
            rx: 10,
            strokeWidth: 4,
            stroke: color,
            opacity: 0,
            fill: "none"
        },
        visible: {
            x: 31,
            y: 3.5,
            width: 20,
            height: 100,
            rx: 10,
            strokeWidth: 4,
            stroke: color,
            opacity: 1,
            fill: "none",
            transition: {
                type: "spring",
                damping: 12,
                stiffness: 50,
            }
        },
        exit: {
            x: 31,
            y: 3.5,
            width: 20,
            height: 10,
            rx: 10,
            strokeWidth: 4,
            stroke: color,
            opacity: 0,
            fill: "none",
            transition: {
                type: "spring",
                damping: 10,
                stiffness: 50,
            }
        }
    }

    const horzRect = {
        hidden: {
            width: 10,
            strokeWidth: 4,
            stroke: color,
            opacity: 0,
            fill: "none"
        },
        visible: {
            width: 55,
            strokeWidth: 4,
            stroke: color,
            opacity: 1,
            fill: "none"
        },
        exit: {
            width: 10,
            strokeWidth: 4,
            stroke: color,
            opacity: 0,
            fill: "none",
            transition: {
                type: "spring",
                damping: 10,
                stiffness: 50,
            }
        }
    }

    const line = {
        hidden: {
            pathLength: 0,
            strokeWidth: 10,
            stroke: color,
            opacity: 0,
            fill: "none"
        },
        visible: {
            pathLength: 1,
            strokeWidth: 10,
            stroke: color,
            opacity: 1,
            fill: "none",
            transition: {
                type: "spring",
                damping: 15,
                stiffness: 60,
            }
        },
        exit: {
            pathLength: 0,
            strokeWidth: 10,
            stroke: color,
            opacity: 0,
            fill: "none",
            transition: {
                type: "spring",
                damping: 15,
                stiffness: 60,
            }
        }
    }

    const gg = {
        hidden: {
        },
        visible: {
            transition: {
                when: "beforeChildren",
                staggerChildren: 0.1,
            }
        },
        exit: {
            transition: {
                type: "spring",
                damping: 1,
                stiffness: 50,
                when: "afterChildren",
                staggerChildren: 0.1
            }
        }
    }

    const partial = {
        hidden: {
            pathLength: 0,
            strokeWidth: 5,
            stroke: color,
            fill: "none"
        },
        visible: {
            pathLength: 1,
            strokeWidth: 5,
            stroke: color,
            fill: "none",
            transition: {
                type: "spring",
                damping: 1,
                stiffness: 1,
            }
        },
        exit: {
            pathLength: 0,
            strokeWidth: 5,
            stroke: color,
            fill: "none",
            transition: {
                type: "spring",
                damping: 15,
                stiffness: 60,
            }
        }
    }
    const ratio = 60 / 70

    if (width && !height) {
        height = width / ratio
    } else if (!width && height) {
        width = height * ratio
    } else if (!width && !height) {
        width = 60
        height = 70
    }

    const circleControls = useAnimation()
    const groupControls = useAnimation()
    const partialControls = useAnimation()

    const sequence = async () => {
        circleControls.start("visible")
        await sleep(3000)
        circleControls.start("exit")
        await sleep(700)

        groupControls.start("visible")
        await sleep(3000)
        groupControls.start("exit")
        await sleep(600)

        partialControls.start("visible")
        await sleep(3000)
        partialControls.start("exit")
        await sleep(400)

        sequence()
    }

    useEffect(() => {
        sequence()
    }, [])

    return (
        <div className="relative" style={{ width, height, ...style }}>
            <div className="absolute w-100 h-100">
                <svg
                    xmlns="http://www.w3.org/2000/svg"
                    viewBox="0 0 85 125"
                >
                    <motion.g
                        animate={circleControls}
                        variants={playfullGroup}>
                        <motion.circle
                            variants={circle}
                            initial="hidden"
                            cx="73.5"
                            cy="10.5"
                            r="9.5"
                            fill={color} />

                        <motion.rect
                            variants={vertRect}
                            initial="hidden"
                        />

                        <motion.rect
                            variants={horzRect}
                            initial="hidden"
                            x="2"
                            y="3.5"
                            width="55"
                            height="15" />
                    </motion.g>
                </svg>
            </div>
            <div className="absolute w-100 h-100">
                <svg
                    xmlns="http://www.w3.org/2000/svg"
                    viewBox="0 0 85 125"
                    onClick={sequence}>
                    <g strokeLinecap="round">
                        <motion.g
                            variants={gg}
                            animate={groupControls}
                        >
                            <motion.path
                                d="M8.0,5.5 L8.0,18.5"
                                variants={line}
                                initial="hidden"
                            />
                            <motion.path
                                d="M32.5,5.516018857 L32.5,102.5"
                                variants={line}
                                initial="hidden"
                            />
                            <motion.path
                                d="M53.5,5.516018857 L53.5,102.5"
                                variants={line}
                                initial="hidden"
                            />
                            <motion.path
                                d="M77.5,5.5 L77.5,18.5"
                                variants={line}
                                initial="hidden"
                            />
                        </motion.g>
                    </g>
                </svg>
            </div>
            <div className="absolute w-100 h-100">
                <svg
                    xmlns="http://www.w3.org/2000/svg"
                    viewBox="0 0 95 125"
                    onClick={sequence}>
                    <g fillRule="evenodd">
                        <g transform="translate(5, 10)">
                            <motion.path
                                d="M84,0 L84,19 L53,19 L53,92.0063948 M53,103 L31,103 L31,19 L0,19 M0,7.96408931 L0,0 L66.2441139,0 L72.993586,0"
                                variants={partial}
                                initial="hidden"
                                animate={partialControls}
                            />
                        </g>
                    </g>
                </svg>
            </div>
        </div>
    )
}

function sleep(ms: number) {
    return new Promise(resolve => setTimeout(resolve, ms));
}

export default Logo
