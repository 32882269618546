/**
 * Layout component that queries for data
 * with Gatsby's useStaticQuery component
 *
 * See: https://www.gatsbyjs.org/docs/use-static-query/
 */

import React from "react"
import PropTypes from "prop-types"
import { useStaticQuery, graphql } from "gatsby"
import Logo from "./logo"
import Header from "./header"

import "normalize.css/normalize.css"
import "tachyons/css/tachyons.min.css"
import "./layout.scss"

export interface LayoutProps {
    headerTop?: MotionValue
    headerPosition?: MotionValue
    headerMenuOpacity?: MotionValue
    children: JSX.Element[] | JSX.Element | string
}

const Layout = ({ headerTop = 0, headerPosition, headerMenuOpacity, children }: LayoutProps) => {
    const data = useStaticQuery(graphql`
    query SiteTitleQuery {
      site {
        siteMetadata {
          title
        }
      }
    }
  `)

    return (
        <>

            <div className="spacer" style={{ marginBottom: 80 }} />
            <Header
                headerTop={headerTop}
                headerPosition={headerPosition}
                headerMenuOpacity={headerMenuOpacity} />
            <main>{children}</main>
            <Footer />
        </>
    )
}

Layout.propTypes = {
    children: PropTypes.node.isRequired,
}

export default Layout

const Footer = () => {
    return (
        <footer className="pa4 pa5-l bt b--dark-gray relative">
            <div className="dt dt--fixed w-100" >
                <div className="dn dtc-ns v-mid">
                    <p className="f7  dib pr3 mb3">
                        Copyright © Tacitic {new Date().getFullYear()}
                    </p>
                </div>
            </div>
            <div className="db dn-ns">
                <p className="f7  mt4 tc">
                    Copyright © Tacitic {new Date().getFullYear()}
                </p>
            </div>
        </footer>
    )
}
