import React from "react"
import { Link } from "gatsby"
import Logo from "../components/logo"

export interface LogoTextProps {
    width?: number,
    height?: number,
    fontSize?: number | string,
}

const LogoText = ({ width, height, fontSize = "2rem" }: LogoTextProps) => {
    return (
        <Link to="/" className="flex items-center ml-auto mr-auto">
            <Logo width={width} height={height} />
            <span className="b ml1" style={{
                fontSize,
                userSelect: "none",
                color: 'var(--logo-color)'
            }}>
                tacitic.
            </span>
        </Link>
    )
}

export default LogoText
