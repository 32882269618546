import PropTypes from "prop-types"
import React from "react"
import LogoText from "./logo-text"
import { motion, useViewportScroll, useTransform, useMotionValue, useElementScroll, MotionValue } from "framer-motion"
import { Link } from "gatsby"

interface HeaderProps {
    headerTop?: MotionValue
    headerPosition?: MotionValue
    headerMenuOpacity?: MotionValue
}

const Header = ({ headerTop, headerPosition, headerMenuOpacity }: HeaderProps) => (
    <>
        <motion.header className="w-100 pv3 ph0-ns dn db-ns" style={{ top: headerTop, position: headerPosition }}>
            <div className="ml-auto mr-auto flex flex-row items-center w-100" style={{ height: 48, maxWidth: 960 }}>
                <LogoText width={40} fontSize="1.6rem" />
                <motion.div className="flex justify-between ml5 mt1" style={{ opacity: headerMenuOpacity, flexGrow: 1 }}>
                    <a href="#">About us</a>
                    <a href="#">People</a>
                    <Link to="/flux">Flux</Link>
                    <a href="#">Events</a>
                    <a href="#">Contact</a>
                </motion.div>
            </div>
        </motion.header>


        {/* Mobile header */}
        <header className="header-mobile dn-ns db flex pt5">
            <LogoText width={70} fontSize="2.2rem" />
        </header>
    </>
)

export default Header
